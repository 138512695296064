import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel2";
import "../../../../node_modules/react-owl-carousel2/lib/styles.css";
import "./MenuTKB.scss";

import ModalTKB from "./tkb modal/ModalTKB";
import Skeleton from "react-loading-skeleton";

const options = {
    dots: true,
    dotsEach: true,
    dotData: true,
    items: 1,
    rewind: true,
    mouseDrag: true,
    touchDrag: true,
    dotsClass: "owl-dots",
    autoplay: false,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: false,
    startPosition: 0,

    // navText: ["<i class='fa fa-chevron-left icons' ></i>", "<i class='fa fa-chevron-right icons' ></i>"]
};
class MenuTKB extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataDetailTKB: {},
            dataTKB: [
            ]
        };
        this.onClickTkbItemHandler = this.onClickTkbItemHandler.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                dataTKB: [
                    {
                        title: 'TKB90',
                        value: this.props.data.tkb90,
                        // value: '75.04%',
                        body: 'Adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 90 (sembilan puluh) hari kalender terhitung sejak jatuh tempo.'
                    },
                    {
                        title: 'TKB60',
                        value: this.props.data.tkb60,
                        body: 'adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 60 (enam puluh) hari kalender terhitung sejak jatuh tempo.'
                    },

                    {
                        title: 'TKB30',
                        value: this.props.data.tkb30,
                        body: 'adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 30 (tiga puluh) hari kalender terhitung sejak jatuh tempo.'
                    },
                    {
                        title: 'TKB0',
                        value: this.props.data.tkb0,
                        body: 'adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 0 (nol) hari kalender terhitung sejak jatuh tempo.'
                    },

                ]
            })
        }
    }
    componentDidMount() {
        this.setState({
            dataTKB: [
                {
                    title: 'TKB90',
                    value: this.props.data.tkb90,
                    body: 'dalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 90 (sembilan puluh) hari kalender terhitung sejak jatuh tempo.'
                },
                {
                    title: 'TKB60',
                    value: this.props.data.tkb60,
                    body: 'adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 60 (enam puluh) hari kalender terhitung sejak jatuh tempo.'
                },

                {
                    title: 'TKB30',
                    value: this.props.data.tkb30,
                    body: 'adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 30 (tiga puluh) hari kalender terhitung sejak jatuh tempo.'
                },
                {
                    title: 'TKB0',
                    value: this.props.data.tkb0,
                    body: 'adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan 0 (nol) hari kalender terhitung sejak jatuh tempo.'
                },
            ]
        })
    }
    onClickTkbItemHandler(data) {
        this.setState({ dataDetailTKB: data });
        this.props.toggle()
    }
    render() {

        return (
            <div className={this.props.className}>
                <div className="tkb tkb-carousel" >
                    {this.props.data?.tkb0 !== '' ?

                        <OwlCarousel options={options}>
                            {this.state.dataTKB?.map((item, id) => (
                                <div key={id} onClick={() => this.onClickTkbItemHandler(item)} style={{ cursor: 'pointer' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap:4}}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <p className="mb-0 lh-1 fs-n2">{item.title?.toString().toUpperCase()} :</p>
                                            <p className="mb-0 lh-1 text-primary fs-2">
                                                {item?.value.toString().split('.')[0].length>1?item?.value.toString().substring(0, 5):item?.value.toString().substring(0, 4)}%
                                            </p>
                                        </div>

                                        <span
                                            className="ml-2 d-inline-flex tkb-toggler"
                                            tabIndex="0"
                                            id={this.props.id}
                                            data-html="true"
                                            onMouseEnter={this.props.onHover}
                                            onMouseLeave={this.props.onHoverLeave}>
                                            <i className="material-icons-outlined text-primary">info</i>
                                        </span>
                                    </div>
                                </div>
                            ))}


                        </OwlCarousel>
                        :
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p className="mb-0 lh-1 fs-n2">TKB:</p>
                                <Skeleton height={25} width={100} />
                            </div>
                        </div>

                    }

                </div>
                {Object.keys(this.state.dataDetailTKB).length > 0 && (
                    <ModalTKB isOpen={this.props.isOpenModal} toggle={this.props.toggle} data={this.state.dataDetailTKB} />
                )}
            </div>
            // <div className={this.props.className}>
            //     <div className="tkb">
            //         <div>
            //             <p className="mb-0 lh-1 fs-n2">TKB90:</p>
            //             <p className="mb-0 lh-1 text-primary fs-2">
            //                 {this.props.data}%
            //                 <span
            //                     className="ml-2 d-inline-flex tkb-toggler"
            //                     tabIndex="0"
            //                     id={this.props.id}
            //                     data-html="true"
            //                     onMouseEnter={this.props.onHover}
            //                     onMouseLeave={this.props.onHoverLeave}>
            //                     <i className="material-icons-outlined text-primary">info</i>
            //                 </span>
            //             </p>
            //         </div>

            //         <Popover
            //             placement="bottom"
            //             target={this.props.id}
            //             className={this.props.popoClass}
            //             isOpen={this.props.isOpen}
            //             toggle={this.props.toggle}>
            //             <PopoverHeader className="popover-header"></PopoverHeader>
            //             <PopoverBody>
            //                 <span className="font-weight-semi-bold">TKB90</span> adalah tingkat keberhasilan penyelenggara Layanan Pendanaan Bersama
            //                 Teknologi Informasi dalam dalam memfasilitasi penyelesaian kewajiban pendanaan bersama dalam jangka waktu sampai dengan 90
            //                 (sembilan puluh) hari sejak tanggal jatuh tempo. TKB90 dihitung dari 100% dikurangi nilai TKW90.
            //             </PopoverBody>
            //         </Popover>
            //     </div>
            // </div>
        );
    }
}
export default MenuTKB;
