import React from 'react'
import './style.scss'
import { ReactComponent as ExclamationIcon } from '../../assets/svg/exclamation-triangle-fill.svg'


export default function NotActivated({ onClickActionHandler }) {
    return (
        <div className='not-activated__wrapper'>
            <div className='not-activated__inner-wrapper'>
                <ExclamationIcon />

                <div className='not-activated__text-wrapper'>

                    <p className='title'>Anda belum melakukan aktivasi Digisign</p>
                    <p className='subtitle'>Silahkan melakukan aktivasi Digisign untuk melanjutkan pendanaan project</p>
                    <p className='action-text' onClick={onClickActionHandler}>Klik disini untuk aktivasi</p>
                </div>
            </div>
        </div>
    )
}
