import React, { Component } from "react";
import Info from "./AllProyekInfo";
import Bayar from "./AllProyekBayar";
import TabInfo from "./AllProyekTab";

class ItemDetailProyek extends Component {
    render() {
        return (
            <div>
                <section id="proyek-detail-page" className="with-pad">
                    <div className="container">
                        <div className="project-detail">
                            <div className="row mb-3">
                                <div className="col-12 col-md-7">
                                    <div className="mb-4">
                                        <Info
                                            project={this.props.project}
                                            diffDays={this.props.diffDays}
                                            progressBar={this.props.progressBar}
                                            sisa={this.props.sisa}
                                            namaSektor={this.props.namaSektor}
                                            dana_proteksi={this.props.dana_proteksi}
                                            total_pendanaan={this.props.total_pendanaan}
                                        />
                                    </div>
                                    <TabInfo project={this.props.project} />
                                </div>
                                <Bayar
                                    class={"col-12 col-lg-5"}
                                    estimasiBunga={this.props.estimasiBunga}
                                    project={this.props.project}
                                    saldo={this.props.saldo}
                                    kyc={this.props.kyc}
                                    history={this.props.history}
                                    update={this.props.update}
                                    pinjaman={this.props.project.tipe_pinjaman}
                                    proyek_id={this.props.proyek_id}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default ItemDetailProyek;
