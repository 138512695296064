import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import LogoDipay from "./logo-dipay-text.svg";
import { NavItem } from "reactstrap";
import NumberFormat from "react-number-format";
import ShowElement from "../../../library/components/ShowElement";

class SidebarMenu extends Component {
    loading(data) {
        if (this.props.loading) {
            return (
                <Skeleton />
                // 'loading'
            );
        } else {
            if (data === 1) {
                return "PEMBERI DANA PERSONAL";
            } else if (data === 2) {
                return "PEMBERI DANA BADAN HUKUM";
            } else {
                return data;
            }
        }
    }

    loadingSaldo(data) {
        const { saldo: isShowSaldoOnly } = this.props.isShowSaldo;

        if (this.props.loading) {
            return <Skeleton width={100} />;
        } else {
            if (isShowSaldoOnly) {
                if (!data) {
                    return "Rp 0";
                } else {
                    return "Rp " + data;
                }
            } else {
                return "*****";
            }
        }
    }

    loadingSaldoDipay(data) {
        // if(!data){
        //     return(
        //         <Skeleton width={100}/>
        //     )
        // } else {
        const { dipay: isShowSaldoDipay } = this.props.isShowSaldo;

        if (this.props.loading) {
            return <Skeleton width={100} />;
        } else {
            if (isShowSaldoDipay) {
                if (!data) {
                    return "Rp 0";
                } else {
                    return "Rp " + data.toLocaleString("IN");
                }
                // }
            } else {
                return "*****";
            }
        }
    }

    render() {
        const lenderDetail = () => {
            const lender = this.props.lender;
            if (lender === undefined) {
                return (
                    <Link className="nav-link">
                        <i className="material-icons-outlined">account_circle</i>
                        <span>Akun</span>
                    </Link>
                );
            } else {
                return (
                    <Link
                        to={{
                            pathname: "/lender/akun/" + lender._id,
                            state: {
                                jenis: `${this.props.jenis}`
                            }
                        }}
                        className={this.props.location.pathname === "/lender/akun/" + lender._id ? "nav-link active" : "nav-link"}>
                        <i className="material-icons-outlined">account_circle</i>
                        <span>Akun</span>
                    </Link>
                );
            }
        };

        const { saldo: isShowSaldoOnly, dipay: isShowSaldoDipay } = this.props.isShowSaldo;
        //   console.log(!this.props.saldoDipay ? '0': this.props.saldoDipay)
        // console.log(!this.props.saldoDipay)
        return (
            <div className="col-12 col-lg-3">
                <div className="card border-0 shadow sidebar" id="sidebar">
                    <div className="card-body shadow">
                        <div className="text-center mb-4">
                            <p className="fs-n1 font-weight-semi-bold text-primary">{this.loading(this.props.jenis)}</p>
                            <p className="font-weight-semi-bold mb-1">{this.loading(this.props.nama_pengguna)}</p>
                            <p className="text-secondary mb-0">{this.loading(this.props.email)}</p>
                        </div>
                        <div className={`mb-4 mb-xl-2 position-relative d-flex flex-column ${this.props.saldo.length > 7 && isShowSaldoOnly ? 'flex-xl-col': 'flex-xl-row' } gap-1 justify-content-between`}>
                            <div style={{minWidth:'max-content'}}>
                                <i className="material-icons-outlined text-primary mr-2 ">account_balance_wallet</i>
                                <span style={{ position: "absolute" }} className="font-weight-semi-bold">
                                    Saldo
                                </span>
                                <i className="material-icons-outlined ml-1 saldo">info</i>
                                <p className="text-alert saldo-hover mr-3 text-center">
                                    *Apabila sampai dengan T + 1 tidak dilakukan penarikan, maka akan otomatis ditransfer pada rekening yang terdaftar
                                </p>
                            </div>
                            <div className="float-right d-flex justify-content-between align-items-start">
                                <span className="font-weight-semi-bold mr-2" style={{ fontWeight: "600 !important" }}>
                                    <NumberFormat
                                        value={this.props.saldo}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        decimalScale={0}
                                        renderText={(value) => <span>{this.loadingSaldo(value)}</span>}
                                    />
                                </span>
                                <div className="d-inline-flex">
                                    <ShowElement isShow={isShowSaldoOnly} toggleIsShow={() => this.props.toggleIsShowSaldo()} />
                                </div>
                            </div>
                        </div>
                        {this.props.loading || this.props.loadingLender ? (
                            <Skeleton />
                        ) : !(this.props.lender.lender_type && this.props.lender.lender_type === 2) ? (
                            <div
                                className={`mb-4 d-flex ${
                                    this.props.dipay_key !== undefined && "flex-column gap-1"
                                }   ${this.props.saldo.length > 7 && isShowSaldoOnly ? 'flex-xl-col': 'flex-xl-row' }  justify-content-between `}>
                                <img className="mr-2 " width="60" src={LogoDipay} alt="logo" style={{ marginLeft: "4px" }} />
                                {this.props.dipay_key === undefined ? (
                                    <a
                                        onClick={this.props.toggleModalAktivasi}
                                        className="text-primary font-weight-semi-bold float-right"
                                        style={{ cursor: "pointer" }}>
                                        Aktivasi
                                    </a>
                                ) : (
                                    <div className="d-flex float-right justify-content-between mb-0 align-items-center">
                                        <span className="font-weight-semi-bold  mr-2 " style={{ fontWeight: "600 !important" }}>
                                            {this.loadingSaldoDipay(this.props.saldoDipay)}
                                        </span>
                                        <div className="d-inline-flex">
                                            <ShowElement isShow={isShowSaldoDipay} toggleIsShow={() => this.props.toggleIsShowSaldoDipay()} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}

                        <a href="/proyek" className="btn btn-primary btn-block">
                            Danai Proyek
                        </a>
                        <hr className="my-4" />
                        <ul className="nav flex-column lender-nav">
                            <NavItem>
                                <Link
                                    to="/lender/dasbor"
                                    className={this.props.location.pathname === "/lender/dasbor" ? "nav-link active" : "nav-link"}>
                                    <i className="material-icons-outlined">dashboard</i>
                                    <span>Dashboard</span>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link
                                    to="/lender/portofolio"
                                    className={this.props.location.pathname === "/lender/portofolio" ? "nav-link active" : "nav-link"}>
                                    <i className="material-icons-outlined">assignment</i>
                                    <span>Portofolio</span>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link
                                    to="/lender/dompet"
                                    className={this.props.location.pathname === "/lender/dompet" ? "nav-link active" : "nav-link"}>
                                    <i className="material-icons-outlined">account_balance_wallet</i>
                                    <span>Dompet</span>
                                </Link>
                            </NavItem>
                            <NavItem>{lenderDetail()}</NavItem>
                            <li className="nav-item">
                                <Link to={"/lender/sign"} 
                                className={this.props.location.pathname === "/lender/sign" ? "nav-link active" : "nav-link"}>
                                    <i className="material-icons">edit</i>
                                    <span>Tanda Tangan Digital </span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="/#" className="nav-link" onClick={(e) => this.props.onLogout(e)} style={{ cursor: "pointer" }}>
                                    <i className="material-icons-outlined">exit_to_app</i>
                                    <span>Keluar</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dipay: state.dipay
    };
};
export default withRouter(connect(mapStateToProps)(SidebarMenu));
// export default withRouter(SidebarMenu)
