import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import "./DigisignActivate.scss";
import { ReactComponent as FailedVerification } from '../../../assets/svg/failed-verif.svg'
import { Form, FormGroup, Label, FormText, CustomInput, Input, Button, FormFeedback } from "reactstrap";

function DigisignFailed(props) {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="modal-dialog modal-dialog-centered modal-digisign" tabIndex="-1">
            <ModalHeader toggle={props.toggle} className="px-5 pt-5 pb-0">
                <div className="text-center d-flex flex-column justify-content-center flex-fill">
                    <FailedVerification className="align-self-center " />
                </div>

            </ModalHeader>
            <ModalBody className="px-5 pb-5" style={{ overflowX: 'hidden' }}>
                <div className="wrapper-text">
                    <h5 className="modal-title">Verifikasi Tanda Tangan Digital Gagal</h5>
                    <p className="modal-subtitle">
                        Kami tidak dapat menyelesaikan proses verifikasi tanda tangan digital Anda melalui Digisign. Hal ini mungkin disebabkan oleh data yang tidak sesuai, silahkan hubungi CS kami untuk informasi lebih lanjut
                    </p>
                </div>
                <div className='col-12 flex-1 p-0'>
                    <div className='d-flex flex-column gap-2'>
                        <div className="d-flex align-items-center col-12 ">
                            <Button
                                color="primary"
                                disabled={props.loading}
                                className="btn btn-primary btn-block btn-md d-flex justify-content-center align-items-center gap-1"
                                onClick={()=>props.history.push('/layanan')}>
                                {props.loading &&
                                    <span className="loader"></span>
                                }
                                Layanan Pengaduan
                            </Button>
                        </div>
                        <div className="d-flex align-items-center col-12 ">
                            <Button outline className="btn btn-md btn-block card-submit btn-cancel" onClick={() => { props.toggle() }}>
                                Kembali
                            </Button>

                        </div>

                    </div>
                </div>

            </ModalBody>
        </Modal>
    );
}
export default DigisignFailed;
