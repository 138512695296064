import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import "./DigisignActivate.scss";
import { ReactComponent as DigisignSmallLogo } from '../../../assets/svg/digisign-small-logo.svg'
import { ReactComponent as SignContractIcon } from '../../../assets/svg/sign-contract.svg'
import { Form, FormGroup, Label, FormText, CustomInput, Input, Button, FormFeedback } from "reactstrap";


function DigisignActivate(props) {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="modal-dialog modal-dialog-centered modal-digisign" tabIndex="-1">
            <ModalHeader toggle={props.toggle} className="px-5 pt-5 pb-0">
                <div className="text-center d-flex flex-column justify-content-center flex-fill">
                    <div className="header-sponsorship__wrapper d-flex justify-content-center">
                        <span className="header-sponsorship__text">Powered by</span>
                        <DigisignSmallLogo />
                    </div>
                    <SignContractIcon className="align-self-center " />

                </div>

            </ModalHeader>
            <ModalBody className="px-5 pb-5" style={{ overflowX: 'hidden' }}>
                <div className="wrapper-text">
                    <h5 className="modal-title">Silahkan aktivasi Digisign </h5>
                    <p className="modal-subtitle">
                        Untuk mulai Pendanaan pada platform kami, aktifkan tanda tangan digital Anda. Tanda tangan ini akan digunakan untuk otorisasi dokumen penting dalam proses pendanaan.
                    </p>
                </div>
                <div className='col-12 flex-1 p-0'>
                    <div className='d-flex flex-column gap-2'>
                    <div className="d-flex align-items-center col-12 ">
                            <Button
                                color="primary"
                                disabled={props.loading}
                                className="btn btn-primary btn-block btn-md d-flex justify-content-center align-items-center gap-1"
                                onClick={props.onSubmitHandler}>
                                {props.loading &&
                                    <span className="loader"></span>
                                }
                                Aktivasi
                            </Button>
                        </div>
                        <div className="d-flex align-items-center col-12 ">
                            <Button outline className="btn btn-md btn-block card-submit btn-cancel" onClick={() => { props.toggle() }}>
                                Batal
                            </Button>

                        </div>
                      
                    </div>
                </div>

            </ModalBody>
        </Modal>
    );
}
export default DigisignActivate;
